<template>
  <div
    v-bind="$attrs"
    class="flex flex-col-reverse gap-4 text-xs leading-5 sm:mx-4 md:mx-auto lg:pl-3 xl:pl-20 md:flex-col md:gap-0 md:relative md:bottom-auto md:right-auto md:left-auto"
  >
    <!-- Copyright -->
    <p class="font-normal text-center text-dark-gray-100">
      Keeway Group &copy; {{ $t("common.copyright") }} - {{ year }} Keeway -
      {{ $t("common.all_rights_reserved") }}
    </p>

    <!-- Legal -->
    <div
      class="flex flex-col items-center justify-center gap-1 sm:flex-row md:flex-nowrap lg:justify-around md:gap-4"
    >
      <nuxt-link
        :to="$i18nPath('/terms')"
        class="font-normal text-dark-gray-100 hover:no-underline hover:text-primary hover:text-opacity-70"
      >
        {{ $t("common.terms_and_conditions") }}
      </nuxt-link>
      <nuxt-link
        :to="$i18nPath('/privacy-policy')"
        class="font-normal text-dark-gray-100 hover:no-underline hover:text-primary hover:text-opacity-70"
      >
        {{ $t("common.privacy_policy") }}
      </nuxt-link>
      <nuxt-link
        :to="$i18nPath('/cookie-policy')"
        class="font-normal text-dark-gray-100 hover:no-underline hover:text-primary hover:text-opacity-70"
      >
        {{ $t("common.cookie_policy") }}
      </nuxt-link>
    </div>
  </div>
</template>
<script setup>
const nuxtApp = useNuxtApp();

const $i18nPath = nuxtApp.$i18nPath;
const year = ref(new Date().getFullYear());
</script>